// core
import React, { FC } from 'react';
// components
import Preloader from '../preloader';
// instruments
import { getQueryParameterByName } from '../../helpers';
import { useAuth0 } from '../../helpers/auth';

const PageRedemption: FC = () => {
  // react hooks
  const { loginWithRedirect } = useAuth0();

  // get value of the query parameter 'login_hint'
  const login_hint = getQueryParameterByName('login_hint');

  loginWithRedirect({
    targetUrl: window.location.origin,
    pathname: '/',
    prevent_sign_up: true,
    redemption: true,
    login_hint,
  });

  return <Preloader />;
};

export default PageRedemption;
